module.exports = {
  components: {
    about_overlay: {
      condition_link: 'Terms and Conditions',
      copyright:
        'Hello Visa* Prepaid Card is issued by Peoples Trust Company pursuant to licence by Visa Int. *Trademark of Visa International Service Association and used under licence by Peoples Trust Company. © {year} HELLO NETWORK INC. ALL RIGHTS RESERVED',
      hello_card_link: 'Hello Card',
      merchant_link: 'Businesses',
      politics_link: 'Privacy policy',
      step_1: {
        image: 'about_step_3_en.png',
        sub_text: 'All the balances you get are deposited on your Hello card which is reloadable. Once you have it, you keep it to continue enjoying it.',
        text: 'Did you receive a Hello card?',
      },
      step_2: {
        image: 'about_step_4_en.png',
        sub_text: "It's a Visa card, pay for your purchases like you do with your usual credit or debit card. It's simple, fast and discreet.",
        text: 'Use your Hello card at eligible stores.',
      },
    },
    account_card_required: {
      action: 'Get my Card',
      action_mobile: 'Get my Card',
      message: 'You need to apply for your Hello Visa card.',
      sub_message: 'All your balances will be automatically transferred and you will be able to use them in stores with the card as you usually do with your credit or debit card.',
    },
    account_email_pending_verification: {
      action: 'Send a verification email again',
      action_mobile: 'Send a verification email again',
      message: 'You need to verify your email address',
      modal_message_part_1: 'A verification email has been sent to {email}. ',
      modal_message_part_2: 'Do not forget to check your spam folder if you do not receive the email.',
      modal_title: 'Sent!',
      sub_message: 'An email with a verification link has been sent to you. Remember to check your spam folder or any other secondary folder in your email management application.',
    },
    account_info_bar_activate_card: {
      action_title: 'Activate',
      available_stores: {
        alt_image: 'Check the list of eligible stores.',
        next: 'Next',
        previous: 'Previous',
        text: 'You can use your balances only at eligible merchants. Check the list of merchants associated with each balance on your Hello card in My Account or on the Hello mobile app.',
        title: 'Eligible Stores',
      },
      modal_action: 'Activate',
      modal_input_hint: '6-digit',
      modal_input_label: 'Activation Code',
      modal_success_text: 'Your card has been activated successfully!',
      modal_text: 'When you receive your card by mail, the envelope contains a welcome letter printed with the 6-digit activation code.',
      modal_title: 'Activate your card',
      modal_title_activated: 'What you need to know',
      pin: {
        alt_image: 'Get your card’s PIN with the mobile app.',
        next: 'Next',
        pin_label: 'Your card’s PIN: ',
        text: 'Although your card supports contactless payment, if you need to insert your card into a payment terminal, you will need your card’s PIN. If you forget it, use the Hello mobile app to retrieve your PIN.',
        title: '4-digit PIN',
      },
      recharge: {
        alt_image: 'Reload your card.',
        next: 'Ok, got it',
        previous: 'Previous',
        text: "Don't throw away your Hello card once your balance is spent; it is rechargeable. Increase the value of your card by adding new offers!",
        title: 'Reload your card',
      },
    },
    account_info_bar_base: {
      my_application_id: 'My Hello card application',
      my_card: 'My Hello Card',
      my_money: 'My cash',
      status: 'Status',
      status_value: {
        active: 'Active',
        approved: 'Approved',
        blocked: 'Blocked',
        deactivated: 'Deactivated',
        expired: 'Expired',
        locked: 'Locked',
        lost_stolen: 'Lost / Stolen',
        pending: 'Pending Approval',
        pending_activation: 'Pending Activation',
        rejected: 'Rejected',
        unknown: '---',
      },
    },
    account_info_bar_card: {
      action_title: 'Manage',
      modal_list_1: 'Have access to your balances anytime',
      modal_list_2: 'Add funds to your My Cash account ',
      modal_list_3: 'Get your card PIN if your memory is playing tricks on you',
      modal_list_4: 'Receive push notifications when you make transactions with your card',
      modal_list_5: 'And much more!',
      modal_store: 'Or search "hellocard" in the Stores',
      modal_sub_title: 'Download the companion mobile application of your Hello card to:',
      modal_title: 'Manage your card',
      renew_action: {
        title: 'Renew',
      },
    },
    account_space_consumer_gift_card_header: {
      title: 'My Gift Card Orders',
    },
    account_space_header: {
      img_alt: 'Space logo',
      see_offers: 'See offers',
      website: 'Visit web site',
    },
    account_space_section: {
      empty: 'You have no offer on this card.',
    },
    account_voucher: {
      actual_balance: 'Current balance',
      bonus: 'Bonus',
      bonus_expiration: 'Expires on {date}',
      bonus_hints: {
        link_title: 'How does it work?',
        message: 'The bonus amount will expire on {date}. Once the bonus expires, only the remaining amount of the contribution can be used at the store(s) of the offer.',
        title: 'Bonus',
      },
      contribution: 'Contribution',
      contribution_hints: {
        link_title: 'How does it work?',
        message:
          'The contribution amount never expires and you never lose this money, it belongs to you. However, the unused portion as of {date} will be transferred to your <i>My Cash</i> account on that date.<br/><br/>Funds in your <i>My Cash</i> account can be used with your Hello card at any points of sale that accepts Visa cards in Canada (except gambling, gas at the pump and money services).',
        title: 'Contribution',
      },
      dormancy: {
        dormancy_hints: {
          link_title: 'How does it work?',
          message: 'After {delay} months of inactivity, an inactivity fee of {amount} per month will be applied to the balance until the balance is used again or the balance is empty.',
          title: 'Dormancy',
        },
        dormancy_sub_text: 'Inactivity fees apply from {date}',
      },
      history: 'History',
      merchants_link: 'See stores',
      merchants_modal_title: 'Eligible Stores',
      my_money_transfer: 'Transfers to My Cash on {date}',
      transfer_hints: {
        link_title: 'How does it work?',
        message:
          'The amount never expires and you never lose this money, it belongs to you. However, the unused portion as of {date} will be transferred to your <i>My Cash</i> account on that date.<br/><br/>Funds in your <i>My Cash</i> account can be used with your Hello card at any points of sale that accepts Visa cards in Canada (except gambling, gas at the pump and money services).',
        title: 'Transfer',
      },
    },
    account_voucher_base: {
      canceled: 'Canceled',
      img_alt: 'Gift Card Image',
      transferred: 'Transferred',
    },
    account_voucher_consumer_gift_card: {
      img_alt: 'Gift Card Image',
      merchants_link: 'See stores',
      merchants_modal_title: 'Eligible Stores',
    },
    account_voucher_consumer_gift_card_amount: {
      activation_code_status: {
        active: 'pending activation',
        canceled: 'canceled',
        redeemed: 'activated',
        undefined: 'not available',
      },
      actual_balance: 'Amount',
      consumer_gift_card_download_pdf_modal_action_title: 'Download the gift card',
      consumer_gift_card_payment_success_download_receipt: 'View your receipt',
      consumer_gift_card_send_by_email_modal_action_title: 'Send by Email',
      send_email_status: {
        error: 'error sending email',
        none: 'email not sent',
        sent: 'email sent',
        undefined: 'lacking email status',
      },
      status: 'Status',
    },
    activate_card_step_form: {
      allow_communication: 'Allow {partner} to send me communications and promotions by email',
      and: 'and the',
      card_activated_modal: {
        btnText: 'Ok',
        pin_error: {
          text: 'Try getting it by downloading the Hello mobile app at the end of your card activation process. If you need any assistance, feel free to contact our customer service at support@hellocard.ca.',
          title: "Your card has been activated, but we're unable to provide you with your card PIN due to an unknown error.",
        },
        pin_label: 'Your card PIN',
        pin_text: 'For chip and PIN purchases, you will need your card PIN.',
        reassuring_text: 'You can always get a reminder of your card PIN on the Hello mobile app.',
        title: 'Your card has been successfully activated!',
      },
      cardholder_agreement: 'Cardholder Agreement',
      consent_label: 'I agree to the',
      country_label: 'Country',
      date_of_birth_label: 'Date of Birth',
      electronic_communications_agreement: 'Electronic Communications Agreement',
      error_modal: {
        btnText: 'Ok',
        text: 'Please try activating your card again or contact our customer service at support@hellocard.ca using your 9-digit card reference ID printed on the back of your card.',
        title: 'An error occurred.',
      },
      first_name_label: 'First Name',
      gender_label: 'Gender',
      next: 'Activate',
      postal_code_label: 'Postal Code',
      year_of_birth_label: 'Year of Birth',
    },
    activation_flow_info: {
      header: {
        info_text: 'Your Hello account protects your purchases and gives you access to our customer service.',
        log_in_text: 'Log in or sign up to activate your card',
        profile_alt_text: 'User profile icon',
      },
      step_1: {
        image_alt_text: 'Empty sign up form icon',
        info_text: 'Log in or sign up below.',
      },
      step_2: {
        image_alt_text: 'Filled out, censored, credit card details form icon',
        info_text: 'Enter your card information.',
      },
      step_3: {
        image_alt_text: 'List of stores icon',
        info_text: 'Receive your card PIN and list of eligible stores.',
      },
      step_4: {
        image_alt_text: 'Credit card reader icon',
        info_text: 'Spend your balance with your Hello card in eligible stores.',
      },
    },
    activation_view: {
      back: 'Back to Offer',
      cta_instant_card: 'I received a Hello card',
      cta_redeem: 'I received an activation code',
      subtitle: 'Please choose the applicable case',
      title: 'Activate my Gift Card',
    },
    app: {
      uncaught_error_modal_message: "An unknown error occurred. Please try again or contact Hello's customer service at support@hellocard.ca.",
      uncaught_error_modal_title: 'Error',
    },
    app_stores_badges: {
      download_apple: 'Download on the App Store',
      download_google: 'Get it on Google Play',
    },
    auth_form: {
      action: 'Log In',
      dont_have_account: {
        1: "Don't have a Hello account? ",
        2: 'Sign up now',
        3: ' and say Hello to more money.',
      },
      email_field: 'Email Address',
      forgot_password_action: 'I forgot my password',
      password_field: 'Password',
      title: 'Log Into Hello',
    },
    auth_info_box: {
      subtitle: 'Your Hello account protects your purchases and gives you access to our customer service.',
      title: 'Log in or sign up  to continue',
    },
    big_card: {
      panier_bleu: "Panier Bleu - Faisons rouler l'économie d'ici",
      voucher: 'Gift Card',
    },
    big_card_base_status_label: {
      closed: 'Offer has closed',
      coming_soon: 'Offer starts on {date} {hour}',
      coming_soon_no_date: 'Coming soon',
      expiration: 'Offer expires on {date}',
      sold_out: 'Offer is sold out',
    },
    bonus_offer_publisher_big_card: {
      call_to_action: 'Buy Now',
      progress_left: '{currentNumber} available',
    },
    bonus_offer_publisher_small_card: {
      progress_left: '{currentNumber} available',
    },
    bonus_offer_single_publisher_big_card: {
      call_to_action: 'Buy Now',
    },
    bonus_offer_terms_and_conditions_modal: {
      action_title: 'Ok',
      consent_text: 'By proceeding with the purchase, you agree to the',
      consent_text_link: 'Sales Terms and Conditions.',
      modal_sub_title: 'By proceeding with the purchase and payment of the gift card, you agree to the sales terms and conditions indicated below:',
      modal_text1: 'The gift card is not refundable.',
      modal_text2: 'The gift card will be deposited on your Hello card as a balance and cannot be converted to cash.',
      modal_text3: 'Following the purchase, you will need to apply for your Hello card. The card will be mailed to you within 7-10 business days.',
      modal_text4: 'The balance can be viewed at any time on espacehello.ca or in the Hello mobile app.',
      modal_text5: 'The balance obtained can only be used at eligible stores defined in the gift card program.',
      modal_text6: 'The list of eligible stores is not final and may vary from time to time.',
      modal_text7: 'After {dormancyMonths} months of inactivity, an inactivity fee of {dormancyFixedFee} per month will be applied to the balance until the balance is used again or the balance is empty.',
      modal_text7_with_extension:
        'After {dormancyMonths} months of inactivity, an inactivity fee of {dormancyFixedFee} per month will be applied to the balance until the balance is used again or the balance is empty, unless you made a request to support@hellocard.ca before the end of the {dormancyMonths}th month, in which case no inactivity fee will be applied until the {dormancyExtendedMonths}th month following the date of purchase.',
      modal_text8:
        'Hello Network is not responsible for the funds purchased other than providing access to them through the use of the Hello platform. If the affiliate partner promoting the program ceases to conduct business or honour your funds, your sole recourse for reimbursement for value paid is directly against that affiliate partner, or its insolvency representative.',
      modal_title: 'Sales Terms and Conditions',
    },
    card_application: {
      redirect_link: {
        return_to_account_label: 'Back to My Account',
        return_to_home_label: 'Back to Homepage',
        return_to_offer_label: 'Back to Offer',
        return_to_space_label: 'Back to Space',
      },
    },
    card_selection: {
      activation_renew_required: 'Following your activation, you will receive instructions to renew your card.',
      card_expired: 'Expired',
      card_lost_stolen: 'Lost or stolen',
      card_type_caption_application: 'Ongoing application process',
      card_type_label_application: 'Hello Card with your name',
      card_type_label_gpr: 'Hello Card with your name',
      card_type_label_instant: 'Instant Hello Card',
      purchase_renew_required: 'Following your purchase, you will receive instructions to renew your card.',
    },
    card_summary_desktop: {
      classic_card_fine_print: '18 years and over. Requires an identity verification (automated).',
      classic_card_label: 'Best customer experience',
      classic_card_submit: 'Choose',
      classic_card_title: 'Hello Classic',
      instant_card_submit: 'Choose',
      instant_card_title: 'Hello Instant',
    },
    card_type_selection_modal: {
      detail: 'The Hello card is a reloadable prepaid card. It is not a credit card and it will not affect your credit score.',
      headings: {
        limit: 'Balance Limit',
        open_wallet: 'Open Wallet',
        open_wallet_hint:
          "It's a wallet that contains funds belonging to you, and that you can reload by Interac e-Transfer or Interac debit card with Apple Pay and Google Pay.<br/><br/>These funds can be used at any merchant in Canada that accepts Visa cards (with some exceptions) and are particularly useful when making a transaction in a merchant where the sum exceeds the balance that you can spend at this specific merchant.",
        personalized: 'Personalized (your name)',
        pin: 'Choice of Card PIN',
        rechargeable: 'Reloadable',
        replacement_cost: 'Replacement Cost',
        shipping_time: 'Shipping (business days)',
      },
      redirect_link: {
        return_to_offer_label: 'Back to Offer',
      },
      shipping_time_value: '7-10 days',
      subtitle: 'You must obtain a Hello card to use your balances at eligible stores. Choose the card that suits you best.',
      title: 'Choose my Hello prepaid card',
    },
    change_password_view: {
      action: 'Change',
      password_label: 'Password',
      success_message: 'Your password has been successfully updated',
      title: 'Updating your password',
    },
    claim_gift_certificate_redeem: {
      action: 'Redeem',
      activation_title: 'Enter the activation code of your gift card',
      allow_communication: 'Allow {partner} to send me communications and promotions by email',
      label: 'Activation Code',
      select_card_title: 'Card Selection',
    },
    claim_gift_certificate_success: {
      sub_title: 'Your account has been updated with a new balance!',
      title: 'The gift card has been successfully claimed!',
    },
    classic_card_summary: {
      fine_print: '18 and over. Requires (automated) identity verification.',
      label: 'Best customer experience',
      submit: 'Choose',
      title: 'Hello Classic',
    },
    consumer_gift_card_amount_and_message: {
      label: 'Amount',
      min_max: 'Min. ${min_amount}, max. ${max_amount}',
      msg_label: 'Personalized Message (optional)',
      msg_placeholder: 'E.g., Happy holidays! - Laurie',
    },
    consumer_gift_card_amount_selector: {
      other: 'OTHER',
    },
    consumer_gift_card_corporate_promotion: {
      cta: 'Learn more',
      text: 'Whether to reward your employees or to celebrate an important occasion, our corporate gift card will definitely meet your needs.',
      title: 'For a business?',
    },
    consumer_gift_card_download_pdf_modal: {
      action_title: 'Download the gift card',
      cancel_action: 'Cancel',
      download_action: 'Download',
      download_error_message: 'An error occurred while downloading your gift card',
      download_success_message: 'Your gift card has been successfully downloaded!',
      filename: 'gift-card-{activation_code_id}.pdf',
      language_radios: {
        english: {
          label: 'English',
        },
        french: {
          label: 'French',
        },
        title: 'Please select the language of the PDF',
      },
      modal_title: 'Download the gift card',
    },
    consumer_gift_card_faq: {
      activation: 'Did you receive a gift card?',
      activation_cta: ' Activate it here!',
      answer_1:
        "It's a solution that allows you to buy, send and receive gift cards for a variety of eligible stores and restaurants. Gift cards must be activated and deposited onto a Hello Visa prepaid and reloadable card to enable their use in eligible stores. It is the most reliable, secure and eco-friendly solution on the market.",
      answer_2:
        'You have two options. The easiest is to ask the merchant for a split payment which will allow you to pay the remaining amount with another payment method. In which case you have obtained a personalized Hello card in your name (following identity verification), you can load your My Cash account by following the instructions indicated in the Hello mobile app, and these funds will be used to complete your purchase in the store in a single transaction.',
      answer_3:
        "By creating an account, you will enjoy a personalized experience. You'll be able to easily track your purchases, balances, and access quick support if needed. Join our community now and fully benefit from all the benefits of a connected experience.",
      answer_4:
        'Soon! Unfortunately at the moment the Hello card cannot be added to Apple Wallet or Google Wallet to pay with your mobile phone in stores. We plan to have this feature soon. An email will be sent to all of our registered users to announce this new feature when it becomes available!',
      answer_5: 'Consult our <a style="color: var(--color-ultra-light);" href="{faqUrl}" target="_blank">Get Help</a> page or email us at support@hellocard.ca if you have not found an answer to your problem, we will quickly contact you.',
      faq: 'For any other questions, consult our ',
      faq_cta: 'Get Help',
      faq_cta_suffix: ' page.',
      question_1: 'What is a gift card powered by Hello?',
      question_2: 'What should I do if I want to purchase an item whose price is higher than the amount I have available on my card?',
      question_3: 'Why do I need to create a Hello account to make a purchase or activate a gift card?',
      question_4: 'Can I use Apple Wallet or Google Wallet to pay with the card in stores?',
      question_5: 'What should I do if I have a problem with purchasing, activating or using the card?',
      title: 'Need help?',
    },
    consumer_gift_card_info_modal: {
      action_title: 'Buy as a gift: how does it work?',
      cancel_action: 'Close',
      modal_text_1: 'Choose the amount to give.',
      modal_text_2: 'Add a personalized message (optional).',
      modal_text_3: 'Proceed to payment.',
      modal_text_4: 'Download, print, or send by email. The recipient will activate your gift card online and will receive a Hello card loaded with the gift card by mail.',
      modal_title: 'Buy as a gift: how does it work?',
      offer_consumer_gift_card_action: 'Buy as a Gift',
    },
    consumer_gift_card_list: {
      subtitle: 'Gift cards that offer the freedom to choose!',
      title: 'Gift ideas',
    },
    consumer_gift_card_marketing_card: {
      icon_1: 'mdi-store',
      icon_2: 'mdi-cursor-pointer',
      icon_3: 'mdi-diamond-stone',
      text_1: 'This gift card is the perfect opportunity to encourage local stores!',
      text_2: 'Give your loved ones the freedom to choose from a variety of products and services offered by all our stores.',
      text_3: 'By offering this gift card, you not only support the local economy but also provide a unique experience to each person.',
      title_1: 'Buy Local',
      title_2: 'The Freedom to Choose',
      title_3: 'A Unique Experience',
    },
    consumer_gift_card_offer_small_card: {
      store_count: '{store_count} eligible stores',
    },
    consumer_gift_card_payment_form: {
      allow_communication: 'Allow {partner} to send me communications and promotions by email',
      credit_card_amount: 'By credit card',
      cta_pay: 'Pay',
      description: 'You can use up to {offer_total} of your <i>My cash</i> account to make your payment. The remaining balance, if applicable, will be paid by credit card.',
      detail_title: 'Payment Details',
      heading_credit_card: 'Credit card',
      heading_my_cash: '<i>My cash</i> account<span>{my_cash_amount_max}</span>',
      hint: 'Min. ${min_amount}, max. ${max_amount}',
      my_cash_amount: '<i>My cash</i> amount',
      open_purse_remaining: "You'll have {open_purse_new_total} remaining in your <i>My cash</i> account.",
      pay_with_my_cash: 'Pay with <i>My cash</i>',
      payment_method_title: 'Complete the Payment',
      title: 'Complete the offer purchase',
    },
    consumer_gift_card_payment_success: {
      cta_cancel: 'Close the window and continue shopping',
      cta_merchants: 'View eligible stores',
      cta_my_account: 'Your gift card orders can be found in ',
      cta_my_account_2: 'Go to&nbsp;<i>My Account</i>&nbsp;to view your order',
      cta_my_account_italic: 'My Account.',
      cta_my_account_pending_order: 'Your gift card order is being validated and will be available in the ',
      download_receipt: 'View your receipt',
      order_id: 'Order No.:',
      receipt_email_sent: 'A receipt was sent to your email address',
      redirect_consumer_gifts: 'Go to my gift cards',
      step_1: {
        info_text: 'Download and print the gift card to give it in person or send it by email. Do not activate it before giving it as a gift.',
      },
      step_2: {
        info_subtext: 'IMPORTANT: The printed gift card must not be activated before the exchange.',
        info_text: 'The lucky recipient will activate their gift card online and promptly receive their physical card by mail. If they already have a physical card, the amount will be instantly reloaded onto their card!',
      },
      title: 'The payment has been successfully completed!',
    },
    consumer_gift_card_quote_card: {
      description_1: ' recipient of a gift card',
      description_2: ' gift card buyer',
      name_1: 'Jean Maréchal',
      name_2: 'Caroline Bui',
      quote_1: "I loved receiving this gift card, which works on the Visa network. It's easy to use in store, since you pay just like with a credit card!",
      quote_2: 'The purchase process was pretty simple and quick. I really liked the flexibility of being able to deliver it in person or electronically.',
    },
    consumer_gift_card_send_by_email_modal: {
      action_title: 'Send by Email',
      cancel_action: 'Cancel',
      label_address: 'Recipient Email Address',
      label_name: 'Recipient Name',
      label_sender_name: 'Your Name',
      language_radios: {
        english: {
          label: 'English',
        },
        french: {
          label: 'French',
        },
        title: 'Preferred language of the recipient :',
      },
      modal_text_1: 'We recommend checking with the recipient that the gift card email is not in their spam folder.',
      modal_title: 'Send by Email',
      send_action: 'Send',
      sent_to_message: 'The gift card has been sent to:',
      sent_to_message_error: 'An error occurred while sending the email.',
    },
    consumer_gift_card_store_marketing: {
      see_more_cta: 'See all {closed_loop_size} eligible stores',
      text: 'Give access to {closed_loop_size} stores with just one gift card and let your loved ones enjoy the pleasure of choosing where to use it.',
      title_2_9: 'One card, {closed_loop_size} possibilities',
      title_9_more: 'One card, a myriad of possibilities',
    },
    consumer_gift_card_swap_how_it_works: {
      activate_cta: 'Activate my gift card',
      activate_instruction_1_1: 'Did you receive a gift card with an activation URL and an activation code?',
      activate_instruction_1_2: 'Click here',
      activate_instruction_1_3: 'to activate your gift card.',
      activate_instruction_2: 'Enter the 8-digit activation code to deposit the received amount onto your Hello card.',
      activate_instruction_3: "Your Hello card will be instantly reloaded. If you don't already have a Hello card, you will receive your card within 7 to 10 business days.",
      activate_swap_cta: 'Buy Now',
      activate_tab_title: 'Activate a gift card',
      activate_tab_title_mobile: 'Activate',
      buy_cta: 'Buy now',
      buy_instruction_1: 'Choose the amount to give, add a personalized message, and proceed with the payment.',
      buy_instruction_2: 'You will receive a PDF gift card that you can print to hand over in person or send by email.',
      buy_instruction_3: 'The lucky recipient will activate their gift card online and receive their Hello card by mail. If they already have a Hello card, the amount will be instantly reloaded onto their card.',
      buy_swap_cta: 'Activate my Gift Card',
      buy_tab_title: 'Buy as a gift',
      buy_tab_title_mobile: 'Buy',
      title: "It's simple, flexible, and quick!",
    },
    consumer_gift_card_terms_and_conditions_modal: {
      action_title: 'Ok',
      consent_text: 'By proceeding with the purchase, you agree to the',
      consent_text_link: 'Sales Terms and Conditions.',
      modal_sub_title: 'By proceeding with the purchase and payment of the gift card, you agree to the sales terms and conditions indicated below:',
      modal_text1: 'The gift card is not refundable.',
      modal_text10:
        'Hello Network is not responsible for the funds purchased other than providing access to them through the use of the Hello platform. If the affiliate partner promoting the program ceases to conduct business or honour your funds, your sole recourse for reimbursement for value paid is directly against that affiliate partner, or its insolvency representative.',
      modal_text2: 'The gift card must be activated online with its activation code on its dedicated web page.',
      modal_text3: 'The gift card will be deposited on a Hello card as a balance and cannot be converted to cash.',
      modal_text4: 'If the gift card recipient does not have a Hello card, a new card will be mailed to them within 7-10 business days of activation and card request.',
      modal_text5: 'The balance can be viewed at any time by the recipient of the gift card on espacehello.ca or in the Hello mobile app.',
      modal_text6: 'The balance obtained can only be used at eligible stores defined in the gift card program.',
      modal_text7: 'The list of eligible stores is not final and may vary from time to time.',
      modal_text8: 'After {dormancyMonths} months of inactivity, an inactivity fee of {dormancyFixedFee} per month will be applied to the balance until the balance is used again or the balance is empty.',
      modal_text8_with_extension:
        'After {dormancyMonths} months of inactivity, an inactivity fee of {dormancyFixedFee} per month will be applied to the balance until the balance is used again or the balance is empty, unless you made a request to support@hellocard.ca before the end of the {dormancyMonths}th month, in which case no inactivity fee will be applied until the {dormancyExtendedMonths}th month following the date of purchase.',
      modal_text9: 'A Hello card replacement fee, up to $10 plus taxes, may be required in the event of loss, theft or breakage.',
      modal_title: 'Sales Terms and Conditions',
    },
    consumer_gift_card_totals: {
      subtext: 'Following your purchase, and in the <i>My Account</i> section, you will have access to the options allowing you to distribute, physically or electronically, the gift card to the recipient.',
      title: 'Payment Details',
      total: 'Payment Amount',
    },
    corpo_company_manager: {
      proceed_text: 'Proceed to Checkout',
      title: 'Your Company',
    },
    corpo_company_modal: {
      add: 'Add a New Company',
      cancel: 'Cancel',
      edit: 'Edit Existing Information',
      save: 'Save',
    },
    corpo_company_step: {
      city_label: 'City',
      company_address_label: 'Company Address',
      company_name_label: 'Company Name',
      contact: 'Contact',
      contact_label: 'Contact',
      email_label: 'Email Address',
      name_label: 'Full Name',
      number_of_employees: {
        choices: {
          '1_50': '1-50',
          '201_500': '201-500',
          '500_MORE': '500+',
          '51_200': '51-200',
        },
        label: 'Number of employees',
      },
      phone_number_extension_label: 'Phone Ext.',
      phone_number_label: 'Phone Number',
      postal_code_label: 'Postal Code',
      proceed_text: 'Proceed to Checkout',
      province_label: 'Province',
      title_company: 'Company Information',
      title_contact: 'Contact Information',
    },
    corpo_confirmation: {
      available_in_account: 'You have access to your gift cards and distribution options at any time in your <i>Business Account</i>.',
      batch_id: 'Batch ID:',
      download_invoice: 'Download my receipt',
      download_vouchers: 'Distribute your gift cards',
      go_to_account: 'See my order in my <i>Business Account</i>',
      order_again: 'Place another order',
      order_details: 'Order Details:',
      order_id: 'Order no:',
      order_total: 'Order Total:',
      print_cta: 'Multi-Page PDF File',
      print_label: 'Best option for printing:',
      question_text: 'Any questions? Please contact us at <a href="mailto:support@hellocard.com">support@hellocard.com</a>',
      redirect_corporate_gifts: 'Go to my gift cards',
      share_email_cta: 'ZIP File (single PDF per gift card)',
      share_email_label: 'Best option to share by email:',
      share_whatever_cta_excel: 'Excel File',
      share_whatever_cta_pdf: 'Informative PDF file',
      share_whatever_label: 'Best option to share as you wish and/or for your tax documentation obligations:',
      subtitle: 'A confirmation email has been sent to you. Your order number is <b>{orderId}</b>.',
      title: 'Your gift card order has been successfully completed!',
    },
    corpo_email_verification_required: {
      manual_verify: 'Check email status',
      order_voucher: 'Order gift cards',
      send_link: 'Send a new verification link',
      text: 'Click on the link that was sent to you to verify your email address.',
      title_pending: "We've sent a verification link to your email address.",
      title_success: 'Your email address has been successfully verified!',
    },
    corpo_explanation: {
      action: 'Purchase now',
      faqUrl: 'Any questions? Consult the <a target="_blank" href="{faqUrl}">FAQ</a>.',
      step_1: 'Proceed with the online purchase.',
      step_2: 'Receive gift cards in PDF format with a URL link and activation code.',
      step_3: 'Distribute the gift cards to your employees using the method of your choice: physical (printing) or digital (email delivery).',
      step_4: 'Your employees will activate their gift card and receive a reloadable Hello card by mail (or their existing card will be instantly reloaded if they already have one).',
      title: "It's simple and flexible with the Hello solution",
    },
    corpo_inform_first_account_business_modal: {
      confirm_text: 'OK, proceed to payment',
      text: 'You now have access to a new BUSINESS ACCOUNT link in the black Hello bar at the top of the screen to allow you to access and distribute your gift cards at any time.',
      title: 'Your first business has been successfully created!',
    },
    corpo_intro: {
      img_alt: 'Promotional showcase',
      text: 'Reserved exclusively for businesses. If your order exceeds $10,000, please contact us at <a href="mailto:sales@hellonetwork.ca">sales@hellonetwork.ca</a>.',
      title: 'Employee Recognition Program for Businesses',
    },
    corpo_offer_terms_and_conditions_modal: {
      action_title: 'Ok',
      activation: 'Gift Cards must be activated online with their activation code on the web page dedicated to the activation.',
      consent_text: 'By proceeding with the purchase and payment of the gift card, you agree to the',
      consent_text_link: 'Sales Terms and Conditions',
      expiration: {
        bonus_fixed: 'Gift Cards expire on {bonus_end_date}.',
        bonus_relative: 'Gift Cards expire {bonus_duration_days} days after being purchased.',
        payment_dormancy: 'After {dormancy_activity_trigger} months of inactivity, an inactivity fee of ${dormancy_fee} per month will be applied to the balance until the balance is used again or the balance is empty.',
        payment_dormancy_with_extension:
          'Gift cards are subject to inactivity fees. After {dormancy_activity_trigger} months of inactivity, an inactivity fee of ${dormancy_fee} per month will be applied to the balance until the balance is used again or the balance is empty.',
      },
      modal_sub_title: 'By proceeding with the purchase and payment of the gift card, you agree to the sales terms and conditions indicated below:',
      modal_title: 'Sales Terms and Conditions',
      non_refundable: 'Gift Cards are non-refundable.',
      redeem: 'The only way to redeem a gift card in store is with a Hello card.',
      reserved: 'The program is reserved for businesses.',
      rights: 'Hello Network reserves the right, in its sole discretion, to cancel gift card orders.',
      taxes: 'Taxes apply only if fees are applied to gift cards, and are applicable only to these fees.',
      untradeable: 'Gift Cards will be deposited on a Hello card and cannot be traded for cash.',
    },
    corpo_order_error_modal: {
      ok: 'Ok',
      text: 'Contact us at <a href="mailto:sales@hellonetwork.ca">sales@hellonetwork.ca</a> if you want to place an order that exceeds $10.000. More questions? See our <a href="{faqUrl}" target="_blank">FAQ</a>.',
      title: 'The submitted amount exceeds $10.000.',
    },
    corpo_order_process: {
      step_company_title: 'Company Information',
      step_order_title: 'Order Details',
      step_payment_title: 'Payment',
    },
    corpo_order_step: {
      add_quantity: 'Add another quantity',
      add_quantity_caption: 'Max 5 different quantity',
      amount: 'Amount',
      amount_hint: '${min_amount} min, $500 max',
      amount_label: 'Amount',
      msg_hint: '150 characters max.',
      msg_label: 'Personalized Message (optional)',
      msg_placeholder: 'E.g.: A big thank you to all our employees! Enjoy! - The leadership team',
      proceed_text: 'Next',
      quantity: 'Quantity',
      quantity_hint: '1 gift card min',
      quantity_label: 'Quantity',
      recap_quantity: 'Gift Card Quantity',
      recap_subtotal: 'Sub total',
      recap_warning: "Can't exceeds $10.000.",
      sub_total: 'Line Sub Total',
      subtotal_label: 'Subtotal',
      title: 'Order Information',
    },
    corpo_payment_step: {
      card_name_label: 'Name on card',
      card_name_placeholder: 'Firstname Lastname',
      card_number_label: 'Card Number',
      card_number_placeholder: '1234 1234 1234 1234',
      cvc_label: 'CVC',
      cvc_placeholder: '000',
      expiration_date_label: 'Expiration Date',
      expiration_date_placeholder: 'MM / YY',
      explanation: 'Following your purchase, in the <i>Business Account</i> section, you will have access to the options allowing you to download your gift cards.',
      fees_details: '$5 + 5% per gift card',
      fees_title: 'Fees',
      form_title: 'Make the payment',
      payment_details: 'Payment Details',
      proceed_text: 'Pay',
      taxes_details: 'GST and QST',
      taxes_title: 'Taxes (applicable on fees)',
      total: 'Total',
      vouchers_title: 'Gift Cards',
    },
    corpo_payment_summary: {
      payment_items: {
        fees_title: 'Fees',
        fees_title_with_minimum_fee: 'Fees (min. {minimum_fee} per gift card)',
        tax_description: 'GST + QST',
        tax_title: 'Taxes (applicable on fees)',
        voucher_title: 'Gift Cards',
      },
      subtext: 'Following your purchase, in the <i>Business Account</i> section, you will have access to the options allowing you to download your gift cards.',
      title: 'Payment details',
      total: 'Total',
    },
    corpo_retailers_modal: {
      activator: 'See eligible stores',
      close: 'Close',
      title: 'Eligible Stores List',
    },
    corpo_verifying_email: {
      cancel: 'Cancel',
      error_goback: 'Return to offer page',
      error_send_link: 'Send a new verification link',
      error_text: 'Please make sure the link used is correct or click on the link below to send a new verification link.',
      error_text_no_auth: 'Please make sure the link used is correct or login to resend a new verification link.',
      error_title: 'An error occurred during the email verification',
      order_vouchers: 'Order gift cards',
      success_button: 'OK',
      success_text: 'You can now make business purchases.',
      success_title: 'Your email address has been verified with success!',
      working_title: 'Please wait while we verify your email.',
    },
    corpo_view: {
      coming_soon_offer_message: 'Offer starts on {date}',
      coming_soon_offer_message_no_date: 'Offer coming soon',
      not_purchasable_offer_message: 'Sorry, the offer is no longer available.',
    },
    delete_a_card_action: {
      modal: {
        actions: {
          cancel: 'Cancel',
          confirm: 'Confirm',
        },
        description: 'The following card will be removed from the list:',
        title: 'Remove a credit card',
      },
      title: 'Remove the card',
    },
    destination_modal: {
      see_store: 'See the eligible stores',
    },
    discover_space: {
      title: 'Discover the Hello spaces',
    },
    empty_offers_list: {
      header: 'Sorry',
      message: 'No promotions available at the moment.',
    },
    empty_offers_list_with_category: {
      header: 'Sorry',
      message: 'No promotions available for this category at the moment.',
    },
    find_card_step_form: {
      error_modal: {
        already_activated: {
          text: 'Go to My Account to check your balances.',
          title: 'You have already activated this card.',
        },
        btnText: 'Ok',
        cannot_be_activated: {
          text: 'If you need assistance, feel free to contact our customer service at support@hellocard.ca using your 9-digit card reference ID printed on the back of your card.',
          title: 'This card cannot be activated.',
        },
        instant: {
          card: {
            activation: {
              not: {
                enabled: {
                  text: 'Sorry, this card has not been pre-activated.',
                  title: 'This card cannot be activated.',
                },
              },
            },
            has: {
              no: {
                voucher: {
                  text: 'Sorry, this card has not been pre-activated.',
                  title: 'This card cannot be activated.',
                },
              },
            },
            status: {
              not: {
                pending: {
                  text: 'Go to My Account to check your balances.',
                  title: 'You have already activated this card.',
                },
              },
            },
          },
        },
        internal: {
          server: {
            error: {
              text: 'Internal server error.',
              title: 'Internal Server Error',
            },
          },
        },
        invalid: {
          offer: {
            status: {
              text: 'The offer linked to the card is closed.',
              title: 'Sorry, the card could not be activated.',
            },
          },
        },
        invalid_instant_card_information: {
          link: 'My Account',
          text_1: 'If you’ve received your card by mail, go to ',
          text_2: 'to activate your card with the activation code.',
          text_3: 'Otherwise, please try again or contact our customer service at support@hellocard.ca.',
          title: 'The card information you entered is invalid.',
        },
        no: {
          available: {
            offer: {
              text: 'Sorry, this card cannot be activated. Please contact our customer service at support@hellocard.ca to get help.',
              title: 'This card cannot be activated.',
            },
          },
        },
        reached: {
          instant: {
            card: {
              activation: {
                limit: {
                  day: {
                    text: 'For security reasons, we do not allow redemption of more than 15 cards per day. Please try again tomorrow.',
                    title: 'Sorry, the card could not be activated.',
                  },
                },
              },
            },
          },
        },
        system: {
          bad: {
            gateway: {
              text: 'Bad gateway server error.',
              title: 'Server Error',
            },
          },
        },
      },
      found_card_modal: {
        balance: 'Your Balance',
        dormancy: 'After {delay} months of inactivity, an inactivity fee of ${amount} per month will be applied to the balance until the balance is used again or the balance is empty.',
        expiration_bonus: 'This balance will expire {bonus_expiration_date}.',
        expiration_contribution: 'The unused portion of the balance as of {closure_date} will be transferred to your My Cash account on that date.',
        expiration_mixed: 'The bonus of {bonus_amount} will expire on {bonus_expiration_date} and the contribution of {payment_amount} never expires but the unused portion as of {closure_date} will be transferred to your My Cash account on that date',
        less_detail: 'Hide details',
        more_detail: 'See details',
        ok: 'Ok',
        reassuring_text: "Don't worry, your balances are always available in My Account and on the Hello mobile app.",
        text: "It's preloaded with the following offer:",
        title: 'We found your card!',
      },
      found_card_modal_without_voucher: {
        no_offer_info_text: 'It is currently empty. After its activation, you will be able to top it up with promotional offers.',
        title: 'We found your card!',
      },
      found_multi_offer_instant_card_modal: {
        ok: 'OK',
        text: 'It is preloaded with the following {number_of_offers} balances for a total of {total_amount}:',
        title: 'We have found your card!',
      },
      found_multipublisher_offer_card_modal: {
        multipublisher_selection: {
          header_title: 'You must now select one of the following options:',
          participating_merchant: 'See list of stores',
          sold_out: 'Sold Out',
          store_list_close_modal_title: 'Close',
          store_list_link_title: 'See list of stores',
          store_list_modal_title: 'List of stores',
        },
        text: 'Your card is preloaded with the following offer:',
      },
      info_modal_last_4_digits: {
        image_alt_text: 'Back of card example with last 4 digits of the card number highlighted',
        text: 'Last 4 digits of your card number.',
        title: 'Card Number',
      },
      info_modal_ref_id: {
        image_alt_text: 'Back of card example with reference ID highlighted',
        text: '9 digits on the back of the card.',
        title: 'Reference ID',
      },
      last_4_digits_hint: '**** **** **** XXXX',
      last_4_digits_label: 'Card No Last 4 Digits',
      next: 'Next',
      ref_id_hint: '9 digits on the back of the card',
      ref_id_label: 'Card Reference ID',
    },
    forgot_password_modal: {
      action: 'Send',
      cancel: 'Cancel',
      description: 'Enter and verify your email address. We will send you an email to reset your password.',
      email_field: 'Email Address',
      title: 'No Problem!',
    },
    gift_certificate_card: {
      action: 'Activate my gift card',
      status_label_closed: 'Closed',
      status_label_coming_soon: 'Coming Soon',
      status_label_deactivated: 'Not Available',
      status_label_sold_out: 'Sold Out',
    },
    gift_certificate_card_big: {
      action: 'Activate my gift card',
      status_label_closed: 'Closed',
      status_label_coming_soon: 'Coming Soon',
      status_label_deactivated: 'Not Available',
      status_label_sold_out: 'Sold Out',
    },
    gift_certificate_offer_multiple_publisher_big_card: {
      call_to_action: 'Activate my gift card',
      expiration: 'Offer expires on {date}',
    },
    gift_certificate_offer_publisher_big_card: {
      call_to_action: 'Activate my gift card',
      progress_left: 'Available',
    },
    gift_certificate_offer_publisher_small_card: {
      progress_left: 'Disponible',
    },
    gift_certificate_offer_single_publisher_big_card: {
      call_to_action: 'Activate my gift card',
      call_to_action_consumer_gift_card: 'Buy as a Gift',
      expiration: 'Offer expires on {date}',
    },
    h_chip_big: {
      obtain: 'Get',
      pay: 'Pay',
    },
    h_chip_small: {
      obtain: 'Get',
      pay: 'Pay',
    },
    h_dialog: {
      close: 'Close',
    },
    h_progress_bar: {
      closed: 'Offer has closed',
      coming_soon: 'Offer starts on {date}',
      coming_soon_no_date: 'Offer coming soon',
      condition: 'Minimum {minPurchase} per purchase, maximum {maxPurchase} per user',
      expiration: 'Offer expires on {date}',
      progress_coming_soon: '{totalNumber} remaining',
      progress_left: '{currentNumber} left of {totalNumber}',
      sold_out: 'Offer is sold out',
    },
    home_offers_by_status: {
      available: 'Available promotions',
      coming_soon: 'Promotions coming soon',
      list_all: {
        available: 'View all available promotions  ({numItems})',
        coming_soon: 'View all promotions coming soon ({numItems})',
        missed: 'View all missed promotions ({numItems})',
      },
      return: 'Return',
      show_more: 'Show more',
      statuses: {
        available: 'Available promotions',
        coming_soon: 'Promotions coming soon',
        missed: 'Missed Promotions',
      },
    },
    home_offers_list: {
      escapade: 'Looking for a getaway outside of {region}?',
      list_all: 'See all promotions',
      subtitle: 'Increase your purchasing power',
      title: 'In promotion',
    },
    home_offers_regions: {
      escapade: 'Looking for a getaway outside of {region}?',
      list_all: {
        ab: 'View all promotions in Alberta ({numItems})',
        nb: 'View all promotions in New Brunswick ({numItems})',
        on: 'View all promotions in Ontario ({numItems})',
        qc: 'View all promotions in Quebec ({numItems})',
      },
    },
    home_page_offer_bonus_small_card: {
      space: 'Courtesy of',
    },
    home_search_bar: {
      category: {
        all: 'All Categories',
        fashion_style: 'Fashion & Style',
        food_groceries: 'Food & Groceries',
        home_cars: 'Home and Cars',
        leisure_getaways: 'Leisure & Getaways',
        miscellaneous: 'Miscellaneous',
        mixed: 'Shopping destination',
        restaurants_bars: 'Restaurants & Bars',
      },
    },
    home_status_view: {
      return: 'Return',
      show_more: 'Show more',
      statuses: {
        available: 'Available promotions',
        coming_soon: 'promotions coming soon',
        missed: 'Missed promotions',
      },
    },
    instant_card_activation_view: {
      back: {
        offer: 'Back To offer activation',
        space: 'Back to space',
      },
      login_step: {
        disclaimer: 'Hello Instant Visa Prepaid Card is issued by Peoples Trust Company pursuant to licence by Visa Int. *Trademark of Visa International Service Association and used under licence by Peoples Trust Company.',
      },
      step_1: {
        instruction: "Let's find your card!",
        title: 'Find your card',
      },
      step_2: {
        fact_1: 'The card is not returnable or refundable.',
        fact_2: 'The card is reloadable.',
        fact_3: 'Only Hello Network can load funds onto the card.',
        fact_4: 'The card can be accepted only at eligible stores.',
        fact_5: 'You cannot make cash withdrawals with the card.',
        fact_6: 'Funds loaded onto the card are not insured by the Canada Deposit Insurance Corporation (CDIC).',
        instruction: 'Before activating your card, you need to know...',
        next: 'Next',
        title: 'What you need to know',
      },
      step_3: {
        info: 'We need your information to...',
        info_fact_1: 'protect your balance in case you ever lose your card',
        info_fact_2: 'allow our customer service to identify you for support',
        instruction: 'Please complete the form below.',
        title: 'Activate your card',
      },
      step_4: {
        balance: 'Balance',
        instruction: 'Check out where you can use your new balance.',
        next: 'Ok',
      },
      step_chose_an_option: {
        title: 'Choose an option',
      },
      subtitle: {
        0: 'A Hello account is required to activate your card.',
        1: "You're authenticated!",
        3: "You're one step away from being ready!",
        4: "We've sent you an email with a direct link to see the list of stores. You can also access this list at any time in My Account.",
        '1-2': 'Please follow the steps below to activate your card.',
      },
      title: 'Activate your Hello Instant Visa* Prepaid Card',
    },
    instant_card_all_set_modal: {
      all_done: "I'm done",
      available_apple: 'Available on the App Store',
      available_google: 'Available on Google Play',
      btn_text: 'Send',
      download_incentive1: 'Get a reminder of your PIN if you ever forget',
      download_incentive2: 'Have access to your balances at all times',
      download_incentive3: 'And much more!',
      download_other: 'Or look up "hellocard" in mobile app stores.',
      download_title: 'Download the Hello mobile app to:',
      input_hint: '+1 (XXX) XXX-XXXX',
      input_label: 'Mobile Number',
      more_offers: 'Discover more offers',
      qr_code: 'QR code',
      reloadable: 'Your card is reloadable. Get the most out of it by adding more offers to your card!',
      scan_qr: 'Scan this QR code with your mobile device to download the app.',
      sms_link: 'Or receive by SMS the link to download the app.',
      title: "You're all set!",
    },
    instant_card_apply: {
      action: 'Submit',
      cancel_action: 'Cancel',
      description: 'Please fill out the form below.  We need your information to:',
      hint_city: 'Max 50 characters',
      hint_first_name: 'Max 20 characters',
      hint_last_name: 'Max 20 characters',
      hint_mobile_number: '+1 (123) 123-1234',
      hint_postal_code: 'X9X 9X9',
      hint_street: 'Max 30 characters',
      label_apartment_number: 'Apt.',
      label_city: 'City*',
      label_civic_number: 'Street Number*',
      label_consent: 'I agree to the terms of the <a href="{url1}" target="_blank">Cardholder Agreement</a> and to the <a href="{url2}" target="_blank">Electronic Communications Agreement</a>.',
      label_first_name: 'First Name*',
      label_gender: 'Gender*',
      label_last_name: 'Last Name*',
      label_mobile_number: 'Mobile Number',
      label_postal_code: 'Postal Code*',
      label_province: 'Province*',
      label_street: 'Street Name*',
      label_year_of_birth: 'Year of Birth*',
      point1: 'deliver the card to your home by mail (7 to 10 business days)',
      point2: 'protect your balances in case of card loss',
      point3: 'allow our customer service department to identify you',
      title: 'My Hello Instant Card',
    },
    instant_card_listing_header: {
      card_label: 'Hello Instant Card',
      status: 'Status',
    },
    instant_card_summary: {
      submit: 'Choose',
      title: 'Hello Instant',
    },
    manage_subscription_modal: {
      action: 'Go to management interface',
      info_1: 'No longer have access to bonus offers in the Hello - {space} space',
      info_2: 'Lose any bonus obtained in the Hello - {space} space',
      text: 'Please note that if you cancel your subscription in the management interface, you agree to:',
      title: 'Manage my subscription',
    },
    navbar: {
      about: 'About Hello',
      business_console: 'Business Account',
      close: 'Close',
      faq: 'Get Help',
      language: {
        en: 'en',
        fr: 'fr',
      },
      logo_alt_text: 'Hello Homepage',
      logout: 'Logout',
      my_account: 'My Account',
      nav_alt_text: 'Naviguation Menu',
      signin: 'Sign In',
    },
    offer_card: {
      obtain: 'Get',
      pay: 'Pay',
    },
    offer_card_big: {
      back_action: 'Back to space',
      back_action_publisher: 'Back to offer',
      back_other: 'Back',
      buy_action: 'Purchase',
    },
    offer_card_error_modal: {
      default_text: 'Sorry! An error occurred please refresh the page ({error_code})',
      ok: 'Ok',
      title: 'An error occured while processing your request',
    },
    offer_publishers_display: {
      sub_title: 'No option has yet been added to this offer.',
      title: 'Coming Soon',
    },
    offer_small_card: {
      activate_voucher: 'Activate my gift card',
      buy_as_gift_button: 'Buy as a Gift',
      buy_now_button: 'Buy Now',
      see_offer_button: 'See Offer',
      see_offer_link: 'See Offer',
      see_stores_link: 'See details and eligible stores',
    },
    offer_stores_display: {
      address: '{number_stores} addresse | {number_stores} addresse | {number_stores} addresses',
      destination: '{number_stores} destination | {number_stores} destination | {number_stores} destinations',
      franchise_website: 'Visit web site',
      hide_address_list: 'Hide {number_stores} address | Hide {number_stores} address | Hide {number_stores} addresses',
      hide_list: 'Hide {number_stores} destination | Hide {number_stores} destination | Hide {number_stores} destinations',
      show_address_list: 'Show {number_stores} address | Show {number_stores} address | Show {number_stores} addresses',
      show_list: 'Show {number_stores} destination | Show {number_stores} destination | Show {number_stores} destinations',
      sub_title: 'No store has yet been added to this offer.',
      title: 'Coming Soon',
    },
    open_wallet_hint_modal: {
      title: 'Open wallet',
    },
    overlay_app: {
      extra: 'Or search for "hellocard" in the Stores',
      message: 'Have you downloaded the Hello app? Download it now to have access to your balances and receive alerts when you make transactions with the card.',
      title: 'Hello App',
    },
    overlay_renew_card: {
      action_apply: 'Obtain a new card',
      action_renew: 'Renew my card',
      deactivated: {
        text: 'You must renew your Hello card in order to use your balance in stores.',
        title: 'Your Hello card has been deactivated',
      },
      expire_soon: {
        text: 'You will then need to obtain a new Hello card in order to continue spending your balances in store.',
        title: 'Your Hello card will be expiring on the {date}',
      },
      expired: {
        text: 'You must renew your Hello card in order to use your balance in stores.',
        title: 'Your Hello card has expired',
      },
      lost_stolen: {
        text: 'You must renew your Hello card in order to use your balance in stores.',
        title: 'Your Hello card has been reported lost/stolen',
      },
      success: {
        text: 'You will receive a new Hello card in the mail within 7-10 business days.',
        title: 'Your card has been successfully renewed!',
      },
    },
    overlay_request_card: {
      action: 'Get my Card',
      action_error_option: 'My Account',
      active_card_error_message: 'You have already applied for your Hello card and you cannot get this card twice. Please close the window and refresh your browser.',
      active_card_error_title: 'Sorry!',
      apply_url_error_modal_message: 'Please contact our customer support at support@hellocard.ca.',
      apply_url_error_modal_title: 'An unknown error has occurred',
      description: '<b>You need to apply for your Hello Visa card.</b> All your balances will be automatically transferred and you will be able to use them in stores with the card as you usually do with your credit or debit card.',
      pending_application_error_message: 'You have already applied for your Hello card and you cannot get this card twice. Please close the window and refresh your browser.',
      pending_application_error_title: 'Sorry!',
      required_verify_email_modal_message: 'You must have verified your email address before applying for your Hello card.',
      required_verify_email_modal_title: 'Verify your email address',
      title: 'Your Hello Card',
    },
    overlay_request_card_reminder_modal: {
      cta_get_card: 'Get my Card',
      cta_not_now: 'Later',
      message:
        'You have not applied for your Hello card (<i> Get my card </i> button). Without your Hello card, you will not be able to use the balance(s) you have obtained. Note that you can always proceed to your card application later by going to the <i> My Account </i> section. Please note that once the card application has been completed, your card will be delivered to you by mail within 7 to 10 business days.',
      title: 'Your Hello Card',
    },
    overlay_space_section: {
      email_label: 'Email Address',
      phone_label: 'Phone Number',
      website_label: 'Web Site',
    },
    page_not_found_view: {
      action: 'Go to home page',
      message: 'Sorry, the page does not exist?',
    },
    pay_and_get_consent_modal: {
      action: 'I agree',
      info_1: 'Your email address',
      info_2: 'Your full name',
      info_3: 'Your activity related with the {space_label} offers',
      sub_title: 'By purchasing or activating an offer on the Hello - {space_label} space, you agree that {space_label} has access to the following information in order to improve your experience and the quality of the offers :',
      title: 'I agree',
    },
    pay_and_get_editable: {
      bonus: 'Bonus',
      bonus_notice: 'Expires on {date}',
      dormancy_hints: {
        html_text: 'After {delay} months of inactivity, an inactivity fee of {amount} per month will be applied to the balance until the balance is used again or the balance is empty.',
        link_title: 'How does it work?',
        title: 'Dormancy',
      },
      dormancy_notice: 'Inactivity fees apply from {date}',
      error_payment_over_allowed_for_user: 'The maximum allowed per user for this {offer_branding_label} offer is {limit_amount}. You have {remaining_amount} unused for this offer and you cannot exceed this limit.',
      pay: 'Pay',
      pay_notice: 'Transfers to My Cash on {date}',
      receive: 'Get',
    },
    pay_and_get_editable_input: {
      label: 'Amount With Symbol',
      min_max: 'Min. ${min_amount}, max. ${max_amount}',
    },
    pay_and_get_offer_payment: {
      allow_communication: 'Allow {partner} to send me communications and promotions by email',
      credit_card_amount: 'By credit card',
      description: 'You can use up to {offer_total} of your <i>My cash</i> account to make your payment. The remaining balance, if applicable, will be paid by credit card.',
      detail_title: 'Payment Details',
      heading_credit_card: 'Credit card',
      heading_my_cash: '<i>My cash</i> account<span>{my_cash_amount_max}</span>',
      hint: 'Min. ${min_amount}, max. ${max_amount}',
      my_cash_amount: '<i>My cash</i> amount',
      open_purse_remaining: "You'll have {open_purse_new_total} remaining in your <i>My cash</i> account.",
      pay: 'Pay',
      pay_with_my_cash: 'Pay with <i>My cash</i>',
      payment_method_title: 'Complete the Payment',
      title: 'Complete the offer purchase',
    },
    pay_and_get_purchase_type: {
      for_someone_else: {
        label: 'To be distributed to relatives',
        title: 'Gift-Giving',
        warning: 'Coming Soon',
      },
      for_yourself: {
        at_your_name: 'Hello card at your name',
        label: 'The balance will be deposited on your Hello card',
        select_card: 'Select on which one of your Hello cards the offer will be deposited',
        title: 'Treat yourself',
      },
      title: 'Purchase type',
    },
    pay_and_get_success: {
      sub_title: 'Your account has been updated and a receipt has been sent to you by email!',
      title: 'The payment has been successfully completed!',
    },
    payment_crowdfunding_feature: {
      option_anonymous: {
        label: 'Anonymous contribution',
        text: 'Your name will not be displayed',
      },
      option_visible: {
        label: 'Visible contribution',
        text: 'Your name will be displayed',
      },
      title: 'Visibility of your contribution on the {site} site',
    },
    payment_detail_final: {
      label: 'Payment Required',
    },
    payment_detail_use_my_money: {
      credit: 'Credit',
      label: 'My Cash',
    },
    payment_elements: {
      gst: 'GST - {taxNumber}',
      sales_tax: 'QST - {taxNumber}',
      subscription: 'Monthly Subscription',
      tax: 'Taxes',
    },
    payment_form_v2: {
      tokenization_error: 'Tokenization failed with status: {message}',
    },
    payment_gateway_offline_modal: {
      action: 'ok',
      text: 'We cannot accept payments at the moment due to an issue with our payment processor. Please send an email to support@hellocard.ca, and we will notify you as soon as the service is restored. Thank you for your understanding.',
      title: 'Sorry',
    },
    payment_new_card: {
      save: 'Save my card',
      saved_card: 'Select a saved card',
    },
    payment_saved_card: {
      other_card: 'Manually enter a new card',
    },
    publisher_card: {
      btn_available: 'Select',
      btn_deactivated: 'Not Available',
      btn_sold_out: 'Sold Out',
    },
    publisher_small_card: {
      chose_offer_button: 'Choose',
      see_offer_button: 'See Offer',
      see_stores_link: 'See details and eligible stores',
    },
    purchase_sign_in_overlay: {
      error_offer_closed: 'Closed',
      error_offer_coming_soon: 'Coming Soon',
      error_offer_sold_out: 'Sold Out',
    },
    recently_visited_widget: {
      show_less: 'View less',
      show_more: 'View more',
      title: 'Recently visited Hello spaces',
    },
    redeem_flow_info: {
      step_1: {
        image_alt_text: 'Empty sign up form icon',
        info_text: 'Log in or sign up below.',
      },
      step_2: {
        image_alt_text: 'Filled out, censored, redeem code form icon',
        info_text: 'Enter your 8-digit activation code.',
      },
      step_3: {
        image_alt_text: 'Hello Card in an envelop',
        info_text: 'Get your reloadable Hello card if you don’t already have one. ',
      },
      step_4: {
        image_alt_text: 'Credit card reader icon',
        info_text: 'Spend your balance with your Hello card in eligible stores.',
      },
      subtitle: 'Your Hello account protects your purchases and gives you access to our customer service.',
      title: 'Log in or sign up to continue',
    },
    region_selection: {
      modal: {
        language: {
          en: 'EN',
          fr: 'FR',
        },
        no_region: 'No specific region, all of Canada will do!',
        title: 'Please select a region',
      },
      regions: {
        ab: 'Alberta',
        ca: 'Canada',
        nb: 'New Brunswick',
        on: 'Ontario',
        qc: 'Quebec',
        undefined: '-',
      },
    },
    register_form: {
      action: 'Sign Up',
      description: "<b>Don't have a Hello account yet?</b> Create your account now and say Hello to more money.",
      email_field: 'Your email address',
      have_account: {
        1: 'Already have a Hello account? ',
        2: 'Log in',
        3: ' now.',
      },
      title: 'Sign Up to Hello',
    },
    register_modal: {
      action: 'Sign Up',
      confirm: 'Confirm your Email Address',
      description: 'Create your account and protect it with a password of your choice.',
      email_field: 'Your Email Address',
      password_field: 'Choose your Password',
      title: 'My Hello Account',
    },
    renew_card_modal: {
      action: 'Send',
      apartment_number: {
        label: 'Apt.',
      },
      city: {
        hints: 'Max 50 characters',
        label: 'City*',
      },
      civic_number: {
        label: 'Street Number* ',
      },
      description_classic: 'Choose your card PIN and verify your address. A new card with activation instructions will be sent to this address. All of your current balances will be deposited to this new card upon activation.',
      description_instant: 'Verify your address. A new card with activation instructions will be sent to this address. All of your current balances will be deposited to this new card upon activation.',
      mobile: {
        hints: '+1 (123) 123-1234',
        label: 'Mobile Number',
      },
      pin_card: {
        hints: 'Exactly 4 digit',
        label: 'Card PIN*',
      },
      postal_code: {
        hints: 'X9X 9X9',
        label: 'Postal code*',
      },
      province: {
        label: 'Province*',
      },
      separator_your_address: 'Your address',
      separator_your_card: 'Your card',
      street_name: {
        hints: 'Maximum 50 caractères',
        label: 'Nom de rue*',
      },
      title: 'Renew my card',
    },
    restricted_to_member_badge: {
      text: 'PRIVILEGE OFFER',
    },
    restricted_to_members_modal: {
      auth_cta: 'close',
      auth_text: 'You must be a member of the Hello - {space} to benefit from this offer. To become a member, you must have purchased an offer available to all or have activated a gift card.',
      auth_title: 'You do not have access to this offer',
      no_auth_cta: 'log in',
      no_auth_text: 'You must sign in with your Hello account to get access to the Privilege offers of the Hello - {space}.',
      no_auth_title: 'You must sign in',
    },
    shared: {
      genders: {
        female: 'Female',
        male: 'Male',
        non_binary: 'Non-Binary / Third-Gender',
      },
      provinces: {
        AB: 'Alberta',
        BC: 'British Columbia',
        MB: 'Manitoba',
        NB: 'New Brunswick',
        NL: 'Newfoundland and Labrador',
        NS: 'Nova Scotia',
        NT: 'Northwest Territories',
        NU: 'Nunavut',
        ON: 'Ontario',
        PE: 'Prince Edward Island',
        QC: 'Quebec',
        SK: 'Saskatchewan',
        YT: 'Yukon',
      },
    },
    single_publisher_consumer_gift_certificate_offer: {
      anchor_menu: {
        corporate_desktop: 'Corporate Program',
        corporate_mobile: 'Corporate',
        how_it_works_desktop: 'How it Works',
        how_it_works_mobile: 'How it Works',
        where_to_use_desktop: 'Eligible Stores',
        where_to_use_mobile: 'Stores',
      },
      call_to_action: 'Activate my gift card',
      call_to_action_consumer_gift_card: 'Buy now',
      destination_list: 'Discover all our shopping destinations',
      powered_by: 'Gift card program powered by',
      see_store: 'See the {storeCount} eligible stores',
    },
    small_card: {
      external_website: 'Visit the website (this button opens a new window)',
      panier_bleu: "Panier Bleu - Faisons rouler l'économie d'ici",
      voucher: 'Gift Card',
    },
    small_card_base_status_label: {
      closed: 'Offer has closed',
      coming_soon: 'Offer starts on {date}',
      coming_soon_no_date: 'Offer coming soon',
      expiration: 'Offer expires on {date}',
      no_expiration: '',
      sold_out: 'Offer is sold out',
    },
    space_offer_publisher_view: {
      info_bonus: 'Choose among the options below',
      info_gift_certificate: 'Select the option for which you want to obtain your gift card',
    },
    stay_tuned_small_card: {
      description: 'More promotions will be available soon.',
      title: 'Stay tuned!',
    },
    store_base_status_indicator: {
      status: {
        unready: 'This store is not yet activated',
      },
    },
    store_card: {
      online: 'Online',
      status: {
        ready: 'You can use your card at this store.',
        undefined: 'You can use your card at this store.',
        unready: 'This store is not yet activated (coming soon).',
      },
      visit_web_site_place: 'Visit the website',
      visit_web_site_web: 'Go to the transactional website',
    },
    store_card_big: {
      address_label: 'Address',
      back_action: 'Back',
      phone_label: 'Phone Number',
      website_label: 'Web Site',
    },
    store_list: {
      destination_all: 'All Destinations',
      info_label: 'Eligible stores',
      search_store_placeholder: 'Find a store',
      store_filter_count: 'No store Found | {count} store found | {count} stores found',
      store_filter_empty: 'Unfortunately, no store matches your search.',
    },
    subscription_payment: {
      allow_communication: 'Allow {partner} to send me communications and promotions by email',
      detail_title: 'Payment Details',
      pay: 'Pay',
      payment_method_title: 'Complete the Payment',
      title: 'Complete the subscription purchase',
    },
    subscription_status: {
      cancel_cta: 'Cancel my subscription',
      manage_cta: 'Manage my subscription',
      plan: 'Subscription Plan',
      plan_fee: '{amount} / month',
      status: 'Status',
      status_options: {
        active: 'Active',
        cancelled: 'Cancelled',
      },
    },
    subscription_warning_modal: {
      auth_cta: 'subscribe',
      auth_text: 'You must be a member of the Hello - {space} space at a monthly cost of {amount} / month to take advantage of offers exclusive to {space} members.',
      auth_title: 'Subscription',
      no_auth_cta: 'login',
      no_auth_text: 'You must be logged in to take advantage of offers exclusive to {space} members.',
      no_auth_title: 'Login',
    },
    success_bonus_offer_no_card: {
      cta_close: 'Close the window and continue shopping',
      sub_title: 'A receipt has been emailed to you and your Hello account has been updated. Your next step is to get your Hello card in order to use your balance in store.',
      title: 'The payment has been successfully processed!',
    },
    success_bonus_offer_with_card: {
      cta_close: 'Close the window and continue shopping',
      cta_my_account: 'Go to the&nbsp;<i>My Account</i>&nbsp;section to view my balances',
      sub_title: 'A receipt has been emailed to you and your Hello account has been updated. You can view your balances in the <i>My Account</i> section or in the Hello app.',
      title: 'The payment has been successfully processed!',
    },
    success_gift_certificate_offer_no_card: {
      cta_close: 'Close the window and continue shopping',
      sub_title: 'Your Hello account has been updated. Your next step is to get your Hello card in order to use your balance in store.',
      title: 'The gift card has been successfully claimed!',
    },
    success_gift_certificate_offer_with_card: {
      cta_close: 'Close the window and continue shopping',
      cta_my_account: 'Go to the&nbsp;<i>My Account</i>&nbsp;section to view my balances',
      sub_title: 'Your Hello account has been updated and you can view your balances in the <i>My Account</i> section or in the Hello app.',
      title: 'The gift card has been successfully claimed!',
    },
    success_instant_card_application: {
      description: 'You will receive a Hello card by mail within 7-10 business days.',
      title: 'Your request has been submitted successfully!',
    },
    success_subscription_no_card: {
      cta_close: 'Close the window and shop for offers',
      cta_purchase: 'Continue with the purchase of {offer}',
      sub_title: 'You are now subscribed to the Hello - {space} space and the offers have been unlocked.',
      title: 'The payment has been successfully processed!',
    },
    success_subscription_with_card: {
      cta_close: 'Close the window and shop for offers',
      cta_purchase: 'Continue with the purchase of {offer}',
      sub_title: 'You are now subscribed to the Hello - {space} space and the offers have been unlocked.',
      title: 'The payment has been successfully processed!',
    },
    unsubscribe_modal: {
      action: 'Unsubscribe',
      info_1: 'No longer have access to bonus offers in the Hello - {space} space',
      info_2: 'Lose any bonus obtained in the Hello - {space} space',
      text: 'By canceling your subscription to the Hello - {space} space, you agree to:',
      title: 'Unsubscribe',
    },
    update_email_modal: {
      action: 'Send',
      cancel: 'Cancel',
      confirm: 'Confirm your Email Address',
      description: "An email with a verification link will be sent to your new email address. Don't forget to check your spam folder or any other secondary folder in your email management application.",
      email_field: 'New email address',
      title: 'Update my email address',
    },
    user_profile: {
      change_email: 'Update my email address',
      change_password: 'Update my Password',
      manage_btn: 'Manage',
      my_account: 'My Account',
      my_money: 'My Cash',
      renew_card_success: 'The card renewal request is sent.',
      reset_password_modal_action: 'Send the Link',
      reset_password_modal_cancel: 'Cancel',
      reset_password_modal_close: 'Close',
      reset_password_modal_expiration_warning: 'Please note that the link will expire in one hour.',
      reset_password_modal_text: 'A link to update your password will be sent to your email address.',
      reset_password_modal_title: 'Update your Password',
      reset_password_success: 'The email to updated your password has been successfully sent',
      reset_password_success_title: 'Email successfully sent',
      update_email_modal_action: 'Change',
      update_email_success: 'The verification email has been successfully sent to the new email address',
    },
    verify_email_view: {
      back_action: 'Go to homepage',
      error_message: 'Sorry, your email address could not be verified.',
      expired_message: 'Sorry, your email address could not be verified. The verification link has expired.',
      success_message: 'Your email address has been verified with success!',
    },
    voucher_history: {
      actual_balance: 'Current balance',
      title: 'History',
    },
    vue_virtual_grid: {
      load_more: 'Load more',
    },
  },
  errors: {
    http: {
      account_no_access: 'Account no longer has access to this business',
      account_not_a_member_of_the_partner: 'The account is not a member of this space',
      account_not_found: "The account don't seem to have the permission to operate the action",
      account_not_verified: 'An error occurred while verifying your email',
      account_update_failed: 'The account update failed',
      acquiring_context_offer_invalid_status: 'This offer is no longer available.',
      acquiring_context_publisher_invalid_status: 'This offer is no longer available.',
      activation_code_activation_not_enabled: 'Sorry, this gift card has not been pre-activated.',
      activation_code_already_redeemed: 'This activation code was already activated',
      activation_code_not_active: 'This activation code is invalid',
      activation_code_not_valid: 'This activation code is invalid',
      already_activated: 'Your card has already been activated.',
      already_subscribed_to_plan: 'You are already subscribed please reload the page',
      amount_greater_than_purchase_limit_max: 'The purchase amount is invalid (greater than the maximum required per purchase).',
      amount_less_than_purchase_limit_min: 'The purchase amount is invalid (lower than the minimum required per purchase).',
      amount_lower_than_offer_minimum: 'The purchase amount is invalid (lower than the minimum required per purchase).',
      amount_out_of_fees_inventory_available_amount: 'An error occurred while processing your order',
      amount_out_of_inventory_available_amount: 'The purchase amount exceeds the remaining amount for the offer. Please try with a lower amount.',
      amount_out_of_offer_step_bound: 'The purchase amount is invalid.',
      'auth_auth_internal-error': 'Invalid email address or password',
      'auth_auth_invalid-email': 'The email address is invalid',
      'auth_auth_too-many-requests': 'Too many requests, please wait a couple of minutes and retry',
      'auth_auth_user-disabled': 'Your account has been disabled',
      'auth_auth_user-not-found': 'Invalid email address or password',
      'auth_auth_wrong-password': 'Invalid email address or password',
      cannot_activate_before_activation_start_date: 'Sorry, you can only activate your gift card starting from {activation_date_formatted_time} on {activation_date_formatted_date}.',
      cannot_be_activated: 'This card cannot be activated.',
      card_invalid_activation_code: 'The activation code is invalid',
      card_invalid_status: 'Card is not in the correct status',
      cardholder_already_connected: 'A Hello card is already connected to your account',
      email_already_taken_other_account: 'An account already exist with this email address',
      email_is_not_related_business: 'Account does not have access to this business',
      email_validation_expired: 'Sorry, your email address could not be verified.',
      email_verification_jwt_invalid: 'Sorry, your email address could not be verified.',
      exceed_offer_account_inventory_limit: 'The maximum allowed per user for this {offer_branding_label} offer is {limit_amount}. You have {remaining_amount} unused for this offer and you cannot exceed this limit.',
      exceed_offer_publisher_inventory_limit: 'The maximum allowed per user for this {offer_branding_label} offer is {limit_amount}. You have {remaining_amount} unused for this offer and you cannot exceed this limit.',
      firebase_email_invalid: 'The email address is invalid',
      firebase_email_taken: 'An account already exist with this email address',
      incompatible_interaction_jwt: 'Token use is not valid.  Please reload the page.',
      incompatible_interaction_jwt_offers: 'Token invalid offers do not match.  Please reload the page and try again.',
      incompatible_interaction_jwt_publishers: 'Token invalid publisher do not match.  Please reload the page and try again.',
      incompatible_token_owner: 'Token invalid.  Please reload the page.',
      incorrect_email_status: 'Sorry, an unknown error occurred',
      instant_card_activation_not_enabled: 'Sorry, this card has not been pre-activated.',
      instant_card_status_not_pending: 'This card cannot be activated.',
      invalid_instant_card_information: 'The card information you entered is invalid.',
      invalid_offer_consumer_status: 'The offer is not available',
      invalid_offer_publisher_status: 'This offer is no longer available.',
      invalid_offer_status: 'This offer is not available',
      invalid_offer_type: 'This offer is not available for orders of this type',
      invalid_token: 'Invalid reset password token',
      inventory_not_found: 'Sorry, an error occurred. The offer is not well configured (inventory issue). Please contact customer service.',
      jwt_not_valid: 'Token is invalid',
      jwt_verify_failed: 'Token is invalid',
      linked_consumer_gift_card_voucher_cancelled: 'Sorry, the gift card can’t be activated because it has been canceled by its buyer.',
      no_available_amount_of_inventory: 'Sorry, the offer is sold out and it is no longer possible to purchase gift cards.',
      no_available_offer: 'Sorry, this card cannot be activated. Please contact our customer service at support@hellocard.ca to get help.',
      no_cards_to_activate: 'Oops, your card has already been activated! You can close the window and use your card to make purchases in stores.',
      offer_bad_status_closed: 'Sorry, the offer is not available.',
      offer_is_closed: 'Impossible to get context on a closed offer',
      offer_is_draft: 'Impossible to get context on a draft offer',
      offer_not_found: 'Sorry, an error occurred. The offer has not been found. Please contact customer service.',
      offer_publisher_not_found: 'Sorry, an error occurred. The offer has not been found. Please contact customer service.',
      offer_status_not_available: 'Sorry, the offer is not available.',
      order_corporate_account_not_found: 'Cannot find the corporate order',
      payment_method_used_for_subscription: 'The card cannot be removed. The card is used to pay a subscription on Hello.',
      paysafe_card_expiration_failure: 'Payment error, the card expiration date is invalid',
      paysafe_card_expired: 'Payment error, the card is expired',
      paysafe_card_info_invalid: 'Payment error, the card information is invalid',
      paysafe_card_number_invalid: 'Payment error, the card number is invalid',
      paysafe_card_restricted: 'Payment error, please check your card info or contact our customer service.',
      paysafe_cvv_failure: 'Payment error, the cvv is invalid.',
      paysafe_generic_decline: 'Payment error, please check your card info or contact our customer service.',
      paysafe_insufficient_funds: 'Payment error, please check your card info or contact our customer service.',
      paysafe_limit_refresh_page: 'Payment error, please refresh the page and retry.',
      paysafe_other_error: 'Payment error, please check your card info or contact our customer service.',
      paysafe_retry: 'Payment error, please refresh the page and retry.',
      reached_account_redeem_limit_day: 'Sorry, the gift card could not be activated. For security reasons, we do not allow redemption of more than 15 gift cards per day. Please try again tomorrow.',
      reached_voucher_redeem_limit_day: 'Sorry, you will only be able to activate your gift card starting from {activation_date_formatted_time} on {activation_date_formatted_date}.',
      recaptcha_failed: 'Google recaptcha token is invalid',
      square_card_expired: 'Payment error, the card is expired ({payment_id}).',
      square_card_not_supported: 'Payment error, the card is not supported ({payment_id}).',
      square_cvv_failure: 'Payment error, the cvv is invalid ({payment_id}).',
      square_expiration_failure: 'Payment error, the card is expired ({payment_id}).',
      square_generic_decline: 'Payment error, please correct your card information and retry ({payment_id}).',
      square_insufficient_funds: 'Payment error, the card has insufficient funds ({payment_id}).',
      square_invalid_expiration: 'Payment error, the card is expired ({payment_id}).',
      square_other_error: 'Payment error, please retry or contact our customer service ({payment_id}).',
      subscription_not_found: 'Error with the current Subscription.  Please reload the page.',
      token_uuid_failed: 'The link has expired or is invalid. If you are having trouble recovering your password, please do not hesitate to contact us at support@hellocard.ca.',
      transaction_token_context_invalid: 'An error occurred while processing your order',
      user_already_exists: 'An account with this email address already exists',
      user_already_has_card: 'There is already a card associated with this account',
      user_already_has_pending_application: 'There is already a pending card application associated with account',
      user_not_found: 'The account does not exist',
      voucher_already_linked_to_instant_card: 'This gift card has already been redeemed.',
    },
    refresh_required: 'Sorry! An error occurred please refresh the page and retry ({code})',
    system: {
      system_bad_gateway: 'Unknown API error',
      system_forbidden: 'Forbidden: {message}',
      system_resource_not_found: 'Not found',
      system_unauthorized: 'Unauthorized: {message}',
      validation_error: 'Validation Error: {message}',
    },
    systemError: 'SYSTEM ERROR: ',
  },
  filters: {
    money: '${number}',
    tax: {
      gst: 'GST',
      sales_tax: 'QST',
    },
  },
  page_title: {
    change_password: 'Change Password',
    corpo: 'Gift Card Program for Businesses',
    demo: 'DEV - Demo',
    home: 'Home',
    instant_card_activation: 'Activation',
    my_account: 'My Account',
    offer_activation: 'Activation',
    page_not_found: 'Page Not Found',
    styleguide: 'DEV - Styleguide',
  },
  validation: {
    consent: 'Need to agree',
    fields: {
      activation_code: 'Activation Code',
      birth_date: 'Birth Date',
      card_ref_id: 'Card Reference ID',
      city: 'City',
      email: 'Email Address',
      first_name: 'First Name',
      gender: 'Gender',
      gift_certificate_code: 'Activation Code',
      last_4_digits: 'Card Number Last 4 digits',
      last_name: 'Last Name',
      mobile: 'Mobile number',
      name: 'Name',
      number_of_employees: 'Number of Employees',
      password: 'Password',
      phone: 'Phone number',
      pinCard: 'Card PIN',
      postal_code: 'Postal Code',
      province: 'Province',
      sender_name: 'Your name',
      street: 'Street',
      year_of_birth: 'Year of Birth',
    },
    invalid: {
      activation_code: 'Exactly 6 digits',
      card_pin: 'Exactly 4 digits',
      email: 'Email is invalid',
      email_match: 'Does not match your email address',
      factor: '{value}*{otherFactor} > {maxMultiple}',
      gift_certificate: 'Invalid activation code',
      last_4_digits: 'Invalid character or format.',
      maxLength: 'Maximum {maxLength} characters',
      maxLines: 'Maximum {maxLines} lines',
      maxValue: 'Must be at most {maxValue}',
      minValue: 'Must be at least {minValue}',
      mobile: 'Invalid mobile number.',
      password: 'Minimum 8 characters',
      phone: 'Invalid phone number.',
      positive_float: 'Number is not positive decimal number',
      positive_integer: 'Number is not a positive integer',
      postal_code: 'Invalid character in postal code',
      postal_code_format: 'Invalid format expect A0A 0A0',
      token: 'Invalid character or format.',
      year: 'Invalid format expect YYYY.',
      year_in_future: 'Invalid year. Must not be in the future.',
      year_too_archaic: 'Invalid year. Must be less than 150 years ago.',
    },
    no_repeat: "The same amount can't be repeated",
    required: 'Is required',
  },
};
