import _get from 'lodash/get';
import Vue from 'vue';
import _filter from 'lodash/filter';

async function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export default {
  namespaced: true,
  state: {
    card: null,
    paymentInformation: {},
    showCardSelection: false,
    my_cash_amount_max: null,
    is_valid_context: false,
  },
  getters: {
    my_cash_amount_max: (state) => state.my_cash_amount_max,
    currentCard: (state) => _get(state, 'card', null),
    currentCardStatus: (state) => _get(state, 'card.status', null),
    currentCardPublicToken: (state) => _get(state, 'card.public_token', null),
    currentCardIsRenewable: (state) => _get(state, 'card.is_renewable', null),
    currentCardProgramType: (state) => _get(state, 'card.card_program_type', null),
    currentCardholderId: (state) => _get(state, 'card.cardholder_id', null),
    currentPaymentValidContext: (state) => state.is_valid_context,
    showCardSelection: (state) => state.showCardSelection,
    currentPaymentInformation: (state) => {
      return state.paymentInformation;
    },
    currentPaymentOfferIsAvailable: (state) => _get(state.paymentInformation, 'status') === 'available',
  },
  mutations: {
    reset(state) {
      state.card = null;
      state.paymentInformation = {};
      state.showCardSelection = false;
      state.is_valid_context = false;
      state.my_cash_amount_max = null;
    },
    setShowCardSelection(state, showSelection) {
      state.showCardSelection = showSelection;
    },
    setCurrentCard(state, card) {
      state.card = card;
    },
    setMyCashAmountMax(state, my_cash_amount_max) {
      state.my_cash_amount_max = my_cash_amount_max;
    },
    setCurrentPaymentInformation(state, currentPaymentInformation) {
      state.paymentInformation = currentPaymentInformation;
      state.is_valid_context = true;
    },
  },
  actions: {
    async setCurrentCard({ commit }, card) {
      commit('setCurrentCard', card);
    },
    async getPaymentDetailForOverlay({ rootState, rootGetters, dispatch, commit }, { offer_id, publisher_id, partner_id }) {
      commit('reset');
      let result;
      result = await Vue.prototype.$services.payment.getPrePaymentInfo({
        offer_id,
        publisher_id,
        partner_id,
      });

      result.cards = _filter(result.cards, (card) => ['active', 'pending_activation', 'locked', 'blocked'].includes(card.status) || card.card_program_type !== 'instant');

      commit('setCurrentPaymentInformation', result);
      let currentCard = null;
      let count = 0;
      if (result && result.cards) {
        count = result.cards.length;
        if (result.cards.length > 0) {
          currentCard = result.cards[0];
        }
        if (rootGetters['account/accountHasApplication'] && !rootGetters['account/hasClassicCard']) {
          count = count + 1;
          currentCard = null;
        }
        commit('setShowCardSelection', count > 1);
      }
      commit('setMyCashAmountMax', result.my_cash_amount_max);
      commit('setCurrentCard', currentCard);

      if (rootState.security.authenticated) {
        await dispatch('payment/refreshPaymentMethods', undefined, { root: true });
      }
    },
    async clearOverlayPaymentStore({ commit }) {
      commit('reset');
    },

    async pay(
      { dispatch, commit, state, rootState },
      { payment_method_token, merchant_ref_number, cardholder_id, offer_id, publisher_id, offerHasCrowdfundedFeature, allow_communication, recaptcha_token, tracking_link_id, visitor_uuid, my_cash_amount, credit_card_amount, postal_code },
    ) {
      commit('payment/setPaymentWorking', true, { root: true });

      // create payment intent
      const payload = {
        payment_method: {},
        merchant_ref_number,
        cardholder_id,
        amount: rootState.payment.payment_amount,
        offer_id,
        publisher_id,
        save_card: false,
        allow_communication,
        recaptcha_token,
        tracking_link_id,
        visitor_uuid,
        acquiring_token: state.paymentInformation.acquiring_token,
        postal_code,
      };

      payload.payment_method.token = payment_method_token || null;

      if (my_cash_amount) {
        payload.my_cash_amount = my_cash_amount;
      }

      if (credit_card_amount) {
        payload.credit_card_amount = credit_card_amount;
      }

      // If the current offer has the crowdfunding feature active use the value set by the user or use the default.
      if (offerHasCrowdfundedFeature) {
        payload.features = { crowdfunding: { visible: _get(state, 'current_payment_crowdfunding_feature_visible', state.current_payment_crowdfunding_feature_visible_default_when_active) } };
      }

      // confirm card payment
      const result = await Vue.prototype.$services.payment.orderPayment(payload);

      if (result) {
        await dispatch('payment/paymentSQUAREDONEFlow', result, { root: true });
      } else {
        await sleep(5000);
        await dispatch('account/getAccount', undefined, { root: true });
      }

      if (window.hj) {
        hj('event', 'pay_bonus_offer');
      }
    },
  },
};
