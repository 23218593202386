import Vue from 'vue';
import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _keys from 'lodash/keys';
import _reduce from 'lodash/reduce';
import _includes from 'lodash/includes';

/*
// NOTE: Debug
function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
} */

function isCardStatusActiveForCardApplication(status) {
  return _includes(['blocked', 'locked', 'active', 'pending_activation', 'expired'], status);
}

export default {
  namespaced: true,
  state: {
    can_request_card: false,
    has_any_cards: false,
    can_access_business_console: false,
    id: '',
    email_status: '',
    email: '',

    first_name: undefined,
    last_name: undefined,
    gender: undefined,
    mobile: undefined,
    phone: undefined,
    year_of_birth: undefined,
    address: {},

    cardholder_id: undefined,
    cardholder: {},
    my_money: {},
    application: {},
    vouchers_by_space: {},
    voucher_by_cardholder_by_space: {},
    hasVoucher: false,
    hasUnloadedActiveVoucher: false,

    consumer_gift_card_vouchers: {},
    consumer_gift_card_vouchers_by_space: {},

    subscription_by_space: {},
    activeSubscription: [],
    activeMemberships: [],
    activeMembershipsPartnerId: [],

    instant_cards: [],
    card_to_activate: {},
    virtual_money_amount: 0,

    emailVerified: false,
    verifyingEmail: false,

    card_acquiring_context_token: '',
  },
  getters: {
    emailNeedVerification: (state) => state.email && state.email_status !== '' && state.email_status !== 'verified',
    canAccessBusinessConsole: (state) => state.can_access_business_console,

    accountFirstName: (state) => state.first_name,
    accountYearOfBirth: (state) => state.year_of_birth,
    accountGender: (state) => state.gender,
    accountCountry: (state) => state.address.country,
    accountPostalCode: (state) => state.address.zip,
    accountAddress: (state) => state.address,
    accountMobile: (state) => state.mobile,
    accountEmail: (state) => state.email,
    accountEmailStatus: (state) => state.email_status,
    accountId: (state) => state.id,
    accountVouchers: (state) => state.vouchers_by_space,
    accountSubscriptions: (state) => state.subscription_by_space,
    accountActiveMemberships: (state) => state.activeMemberships,
    accountActiveMembershipsPartnerId: (state) => state.activeMembershipsPartnerId,
    canRequestCard: (state) => state.can_request_card,
    hasClassicCard: (state) => state.email !== '' && !!state.cardholder_id,
    hasAnyCard: (state) => state.has_any_cards,

    accountHasVouchers: (state) => state.hasVoucher,

    accountMoney: (state) => _get(state.my_money, 'balance', 0),
    // classic card
    accountHasActiveClassicCard: (state, getter) => isCardStatusActiveForCardApplication(getter.accountCardStatus) || isCardStatusActiveForCardApplication(getter.accountLatestCardStatus),
    accountClassicCardAddress: (state) => _get(state.cardholder, 'current_classic.cardholder_address', {}),
    accountPublicToken: (state) => _get(state.cardholder, 'current_classic.public_token', ''),
    accountCardStatus: (state) => _get(state.cardholder, 'current_classic.status', 'unknown'),
    accountLatestCardStatus: (state) => _get(state.cardholder, 'latest_card.status', 'unknown'),
    accountCardIsRenewable: (state) => _get(state.cardholder, 'current_classic.is_renewable', false),
    accountClassicCard: (state) => _get(state.cardholder, 'current_classic', {}),

    // Card to activate
    isCardToActivate: (state) => _get(state.cardholder, 'card_to_activate', false),
    accountClassicCardToActivate: (state) => _get(state.cardholder, 'card_to_activate', {}),
    accountCardToActivateAddress: (state) => _get(state.cardholder, 'card_to_activate.cardholder_address', {}),
    accountCardToActivatePublicToken: (state) => _get(state.cardholder, 'card_to_activate.public_token', ''),
    accountCardToActivateStatus: (state) => _get(state.cardholder, 'card_to_activate.status', 'unknown'),

    accountMyMoneyHasOperations: (state) => _get(state.my_money, 'has_operations', false),
    accountHasApplication: (state) => !!state.application && !_isEmpty(state.application) && (state.application.status === 'pending' || state.application.status === 'approved'),
    accountHasPendingApplication: (state) => !!state.application && !_isEmpty(state.application) && state.application.status === 'pending',
    accountApplicationId: (state) => _get(state.application, 'card_application_id', ''),
    accountApplicationStatus: (state) => _get(state.application, 'status', 'unknown'),

    accountInstantCardList: (state) => state.instant_cards,
    accountInstantCardNumber: (state) => _get(state, 'instant_cards.length', 0),
    accountVouchersByCardholderAndBySpace: (state) => state.voucher_by_cardholder_by_space,
    accountConsumerGiftCardVouchersBySpace: (state) => state.consumer_gift_card_vouchers_by_space,
    accountCardholderId: (state) => state.cardholder_id,

    emailVerified: (state) => state.emailVerified,
    verifyingEmail: (state) => state.verifyingEmail,

    cardAcquiringContextToken: (state) => state.card_acquiring_context_token,
  },
  mutations: {
    setInstantCard(state, instantCardList) {
      state.instant_cards = _map(instantCardList, (instantCard) => {
        return {
          status: instantCard.status,
          public_token: instantCard.public_token,
          cardholder_id: instantCard.cardholder_id,
          is_renewable: instantCard.is_renewable,
        };
      });
    },
    setAccount(state, account) {
      state.id = account.id;
      state.email = account.contact.email.value;
      state.email_status = account.contact.email.status;
      state.emailVerified = account.contact.email.status === 'verified';
      state.cardholder_id = account.cardholder_id;
      state.can_request_card = account.can_request_card;
      state.has_any_cards = account.has_any_cards;
      state.first_name = account.first_name;
      state.last_name = account.last_name;
      state.gender = account.gender;
      state.mobile = account.mobile;
      state.phone = account.phone;
      state.year_of_birth = account.year_of_birth;
      state.address = _get(account, 'address', {});
      state.my_money = _get(account, 'my_money', {});
      state.can_access_business_console = _get(account, 'access.business_console', false);
    },

    setVouchers(state, vouchers) {
      state.vouchers_by_space = _groupBy(vouchers, 'partner_id');

      const updatedVouchers = _map(vouchers, (it) => {
        it.groupby_cardholder_id = it.cardholder_id;
        if (!it.cardholder_id) {
          it.groupby_cardholder_id = state.cardholder_id;
        }
        return it;
      });

      const cardholderIdBasedGroupBy = _groupBy(updatedVouchers, 'groupby_cardholder_id');

      state.voucher_by_cardholder_by_space = _reduce(
        _keys(cardholderIdBasedGroupBy),
        (acc, currentCardholderId) => {
          acc[currentCardholderId] = _groupBy(cardholderIdBasedGroupBy[currentCardholderId], 'partner_id');
          return acc;
        },
        {},
      );

      state.hasVoucher = vouchers.length > 0;
      state.hasUnloadedActiveVoucher = !!_find(vouchers, (voucher) => voucher.status === 'active' && !voucher.cardholder_id);
    },
    setConsumerGiftCardVouchersByAccount(state, consumerGiftCardVouchers) {
      state.consumer_gift_card_vouchers = consumerGiftCardVouchers;

      state.consumer_gift_card_vouchers_by_space = _groupBy(consumerGiftCardVouchers, 'partner_id');
    },
    setCardholder(state, cardholder) {
      state.cardholder = cardholder;
    },
    setActiveMemberships(state, activeMemberships) {
      state.activeMemberships = activeMemberships;
    },
    setActiveMembershipsPartnerId(state, activeMembershipsPartnerId) {
      state.activeMembershipsPartnerId = activeMembershipsPartnerId;
    },
    setMyMoney(state, myMoney) {
      state.my_money = myMoney;
    },
    setApplication(state, application) {
      state.application = application;
    },
    setActiveSubscription(state, activeSubscription) {
      state.activeSubscription = activeSubscription;
      state.subscription_by_space = _groupBy(activeSubscription, 'partner_id');
    },
    setEmailVerified(state, verified) {
      state.emailVerified = verified;
    },
    setVerifyingEmail(state, verifying) {
      state.verifyingEmail = verifying;
    },
    setCardAcquiringContextToken(state, token) {
      state.card_acquiring_context_token = token;
    },
    updateMembership(state, newMembership) {
      const index = _findIndex(state.activeMemberships, (membership) => membership.id === newMembership.id);
      const update = {
        ...state.activeMemberships[index],
        allow_communication: newMembership.allow_communication,
      };
      state.activeMemberships.splice(index, 1, update);
    },
    clear(state) {
      state.id = '';
      state.application = {};
      state.email_status = '';
      state.email = '';
      state.cardholder_id = undefined;
      state.cardholder = {};
      state.my_money = {};
      state.vouchers_by_space = {};
      state.hasVoucher = false;
      state.hasUnloadedActiveVoucher = false;
      state.subscription_by_space = {};
      state.activeSubscription = [];
      state.activeMemberships = [];
      state.activeMembershipsPartnerId = [];

      state.first_name = undefined;
      state.last_name = undefined;
      state.gender = undefined;
      state.mobile = undefined;
      state.phone = undefined;
      state.year_of_birth = undefined;
      state.address = {};

      state.card_acquiring_context_token = '';
    },
  },
  actions: {
    async getAccount({ rootGetters, commit, dispatch }) {
      const accountV2 = await Vue.prototype.$services.account.accountInfoV2();

      commit('setAccount', accountV2);
      commit('setInstantCard', accountV2.instant_cards);
      commit('setCardholder', accountV2.cardholder || null);
      commit('setApplication', accountV2.application || null);
      commit('setActiveMemberships', accountV2.active_memberships || []);
      commit('setActiveMembershipsPartnerId', accountV2.active_memberships_partner_id || []);
      commit('setVouchers', _get(accountV2, 'vouchers.items', []));

      await dispatch('getAccountActiveSubscription', null);
      await dispatch('getConsumerGiftCardVouchers', accountV2.id);

      if (window.hj) {
        hj('identify', accountV2.id, { language: rootGetters['ui/currentLocale'] });
      }
    },

    async getConsumerGiftCardVouchers({ commit, dispatch }) {
      const consumerGiftCardVouchersByAccount = await dispatch('consumerGiftCard/listConsumerGiftCardVouchersByAccount', null, { root: true });

      commit('setConsumerGiftCardVouchersByAccount', consumerGiftCardVouchersByAccount);
    },

    async getAccountActiveSubscription({ commit }) {
      const accountActiveSubscription = await Vue.prototype.$services.account.accountActiveSubscription();
      commit('setActiveSubscription', accountActiveSubscription.items);
    },

    async resetAccountStore({ commit }) {
      commit('clear');
    },

    async getAcquiringCardContext({ commit, dispatch }, payload) {
      const result = await Vue.prototype.$services.account.getAcquiringCardContext(payload);
      commit('setCardAcquiringContextToken', result.token);
      return result;
    },

    async activateCard({ dispatch }, payload) {
      const result = await Vue.prototype.$services.account.activateCard(payload);
      if (window.hj) {
        hj('event', 'account_activate_card');
      }
      return result.card_pin;
    },

    async renewCard({}, { card_public_token, card_program_type, card_pin, address, mobile }) {
      const payload = { card_program_type, card_pin, address };

      if (mobile) {
        payload.mobile = mobile;
      }

      const result = await Vue.prototype.$services.account.renewCard(card_public_token, payload);
      if (window.hj) {
        hj('event', 'account_renew_card');
      }
      return result;
    },

    async createCardApplicationContext({}, payload) {
      return await Vue.prototype.$services.account.createCardApplicationContext(payload);
    },

    async checkEmailVerified({ commit }) {
      commit('setVerifyingEmail', true);
      const emailVerifiedReponse = await Vue.prototype.$services.account.accountEmailStatus();
      const emailVerified = emailVerifiedReponse.verified;
      commit('setEmailVerified', emailVerified);
      return emailVerified;
    },
  },
};
