<template>
  <div class="corpo-payment-form">
    <h3 class="header">{{ $t('components.corpo_payment_step.form_title') }}</h3>
    <payment-form-v2 ref="corpoPaymentForm" @readyToPay="(val) => (isReadyToPay = val)" :has-payment-methods="false" />
    <hello-form-error />

    <div class="btn-container">
      <h-btn @click="pay" :loading="working" block :disabled="!hasAContextAndContextIsReady || !isReadyToPay">{{ $t('components.corpo_payment_step.proceed_text') }}</h-btn>
    </div>

    <corpo-offer-terms-and-conditions-modal></corpo-offer-terms-and-conditions-modal>
  </div>
</template>

<script>
import PaymentFormV2 from '@/components/pay-and-get/components/PaymentFormV2';
import CorpoOfferTermsAndConditionsModal from '@/components/corpo/order-process/CorpoOfferTermsAndConditionsModal.vue';
import safeExecute from '@/composables/safe-execute';
import { mapGetters } from 'vuex';
import { recaptcha } from '@/mixins';

export default {
  name: 'corpo-payment-form',
  props: {},
  setup(props, { root }) {
    const { executeForm, working } = safeExecute(root.$store);
    return {
      executeForm,
      working,
    };
  },
  data() {
    return {
      isReadyToPay: false,
    };
  },
  mixins: [recaptcha],
  components: {
    PaymentFormV2,
    CorpoOfferTermsAndConditionsModal,
  },
  computed: {
    ...mapGetters('account', ['accountId']),
    ...mapGetters('ui', ['currentLocale']),
    ...mapGetters('corpo', ['hasAContextAndContextIsReady', 'contextTotal']),
    ...mapGetters('tracking', ['trackingLinkId', 'visitorUuid']),
  },
  async mounted() {
    if (this.$fpjs) {
      const visitor = await this.$fpjs.getVisitorData({ linkedId: this.accountId, tag: { order_type: 'corporate' } });
      console.log(JSON.stringify(visitor, null, 2)); // eslint-disable-line no-console
    }
  },
  methods: {
    async pay() {
      const _this = this;
      const payload = {};

      if (this.trackingLinkId && this.visitorUuid) {
        payload.tracking_link_id = this.trackingLinkId;
        payload.visitor_uuid = this.visitorUuid;
      }

      await this.executeForm(
        {
          beforeAction: async () => {
            payload.recaptcha_token = await this.recaptcha('corpoPayment');
            const { token, merchantRefNum, postal_code } = await this.$refs.corpoPaymentForm.submit(this.contextTotal);
            payload.payment_method_token = token;
            payload.merchant_ref_number = merchantRefNum;
            payload.postal_code = postal_code;
          },
          name: 'pay',
          action: 'corpo/pay',
          success: () => {
            _this.$emit('next');
          },
        },
        payload,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  margin: var(--gap) 0;
  text-align: center;
}
</style>
